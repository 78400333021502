const italianMessages = {
    "hgw": {
        "activity": {
            "create": "Configura un'attività in entrata",
            "direction": { "in": "Entrante", "out": "Uscente" },
            "globalhelp": "Configura il tipo di attività con un nome semplice, quindi seleziona la scheda di configurazione",
            "formulahelp": "Definisci una formula di calcolo corrispondente alle chiavi delle sorgenti.",
            "formulasample": "Esempio: ([A]+[B])/2 richiede di definire 2 sorgenti con le chiavi A e B",
            "label": "Attività",
            "executioncount": "Numero di esecuzioni",
            "executiondate": "Ultima esecuzione",
            "in": "Attività in entrata",
            "out": "Attività in uscita",
            "many": "Attività",
            "metric": {
                "label": "Metrica",
                "delta": "Delta",
                "min": "Minimo",
                "max": "Massimo",
                "in": "Entrante",
                "out": "Uscente",
                "average": "Media",
                "sum": "Somma",
                "count": "Numero"
            },
            "type": { "label": "Tipo di attività" },
            "formula": {
                "key": "Chiave",
                "label": "Formula",
                "sources": "Dati di flusso sorgente",
                "startdate": "Data di avvio",
                "targetfluxitem": "Dato di flusso obiettivo"
            },
            "view": "Configurazione attività in entrata",
            "undefined": "Nessuna attività in uscita"
        },
        "address": {
            "label": "Indirizzo",
            "phone": "Telefono",
            "fax": "Fax",
            "street": "Indirizzo",
            "postalcode": "Codice postale",
            "city": "Città"
        },
        "app": {
            "change": "Vai a",
            "appselector": "Selettore di applicazioni",
            "allapps": "Visualizza tutte le mie applicazioni"
        },
        "authorization": {
            "isvalid": "Validato",
            "tovalidate": "Validare",
            "businessaccount": { "label": "Autorizzazione", "many": "Autorizzazioni" },
            "invalidated": "L'autorizzazione è stata invalidata",
            "organization": { "label": "Membro", "many": "Membri" },
            "validated": "L'autorizzazione è stata validata",
            "validateerror": "Errore nella validazione dell'autorizzazione"
        },
        "badge": {
            "centralkind": { "label": "Centrale", "standard": "Standard", "dungle": "Dungle", "encoder": "Codificatore" },
            "code": "Codice",
            "constraint": "Vincoli",
            "deploy": {
                "label": "Distribuzione",
                "deployed": "Distribuito",
                "toadd": "Da aggiungere",
                "toupdate": "Da modificare",
                "toremove": "Da rimuovere"
            },
            "error": {
                "label": "Errore di configurazione",
                "emptyinfra": "Infra non configurata sull'ULI",
                "outsideinfra": "Infra dell'ULI al di fuori del vincolo del Badge"
            },
            "emptyuser": "Nessun utente",
            "enddate": "Data fine",
            "exclude": "Escludere",
            "excluded": "Escluso",
            "infra": "Restrizione su Infra",
            "infraempty": "Nessuna restrizione",
            "invalidated": "Il badge è stato invalidato",
            "keybox": {
                "label": "Cassette delle chiavi",
                "many": "Sintesi delle distribuzioni nelle cassette delle chiavi degli ULI dello Scope"
            },
            "kind": { "label": "Tipo di badge", "many": "Tipi", "wiegand": "Wiegand", "iso2": "ISO2", "dallas": "Dallas" },
            "label": "Badge",
            "many": "Badge",
            "mediatype": { "label": "Tipo di supporto", "keyboard": "Tastiera" },
            "startdate": "Data inizio",
            "summary": "Distribuzione",
            "usage": {
                "label": "Uso",
                "many": "Usi",
                "masterduplicate": "Duplicazione master",
                "masterkill": "Eliminazione master",
                "resourcecommand": "Comando risorsa",
                "access": "Accesso",
                "zonecommand": "Comando zona"
            },
            "usecount": "Numero di utilizzi",
            "uselimit": "Numero massimo di utilizzi",
            "validated": "Il badge è validato",
            "validateerror": "Errore nella validazione del badge"
        },
        "businessaccount": {
            "allbusinessaccount": "Visualizza tutte le mie attività",
            "autovalidresources": "Validazione manuale/automatica degli ULI",
            "autovalidresourcesno": "Validazione manuale degli ULI",
            "autovalidresourcesyes": "Validazione automatica degli ULI",
            "empty": "Non hai ancora attività ...",
            "create": "Crea una nuova attività",
            "createsuccessfully": "L'attività è stata creata con successo ed è in fase di provisioning ...",
            "label": "Attività",
            "many": "Attività",
            "mybusinessaccount": "Le mie attività",
            "wac": "Identificativo attività WIT",
            "join": "Unirsi a un'attività",
            "joinsuccessfully": "Richiesta di accesso all'attività effettuata con successo ... L'attività sarà visibile non appena la tua autorizzazione sarà validata dal suo amministratore",
            "other": "Altra attività",
            "near": "Attività della tua organizzazione",
            "select": "Seleziona un'attività",
            "selectorg": "Seleziona un'attività nella tua organizzazione",
            "setwac": "Inserisci un WAC (Identificativo attività WIT)",
            "status": { "provisionning": "Provisioning in corso", "deleting": "Archiviazione in corso", "deleted": "Archivio" },
            "offer": { "label": "Offerta" }
        },
        "country": { "label": "Paese" },
        "culture": { "label": "Lingua" },
        "device": {
            "lora": {
                "many": "Lora",
                "deveui": "DevEUI",
                "commissioningMode": "Modalità",
                "lorawanClass": "Classe",
                "model": "Modello",
                "manufacturer": "Produttore"
            }
        },
        "extendedproperty": {
            "label": "Proprietà estesa",
            "many": "Proprietà estese",
            "nullable": "Nullable",
            "create": "Aggiungi una proprietà estesa",
            "edit": "Modifica le proprietà estese",
            "list": { "label": "Elenco dei valori", "key": "Valore", "value": "Testo visualizzato" },
            "resourcetype": "Tipo di risorsa",
            "shared": "Globale",
            "valuetype": {
                "label": "Tipo di valore",
                "bool": "Booleano",
                "int": "Intero",
                "decimal": "Decimale",
                "string": "Testo"
            }
        },
        "flux": {
            "label": "Flusso",
            "origin": { "label": "Origine", "acquiring": "Acquisizione", "cloud": "Cloud" },
            "many": "Flussi",
            "count": "Numero di flussi",
            "size": "Dimensione dei flussi (MB)",
            "parent": "Flusso padre",
            "invalidated": "Il flusso è stato invalidato",
            "item": {
                "code": "Etichetta breve (alfanumerica)",
                "count": "Numero di dati",
                "label": "Dato di flusso",
                "many": "Dati",
                "type": {
                    "label": "Tipo di valore",
                    "boolean": "Booleano",
                    "decimal": "Decimale",
                    "float": "Virgola mobile",
                    "int": "Intero",
                    "money": "Importo",
                    "text": "Testo",
                    "tinyint": "Piccolo intero",
                    "datetime": "Data"
                }
            },
            "point": {
                "label": "Punto",
                "many": "Punti",
                "lastmany": "Ultimi punti",
                "firstdate": "Data del primo punto",
                "lastdate": "Data dell'ultimo punto",
                "lastvalue": "Ultimo valore",
                "count": "Numero di punti"
            },
            "validated": "Il flusso è validato",
            "validateerror": "Errore nella validazione del flusso"
        },
        "infra": {
            "building": "Edificio",
            "heatedsurface": "Superficie riscaldata",
            "isheatedsurfaceoverride": "Definisci superficie riscaldata (altrimenti calcolata con i figli)",
            "isoverride": "Blocca Infra (altrimenti identica all'ULI)",
            "issurfaceoverride": "Definisci superficie globale (altrimenti calcolata con i figli)",
            "includechildren": "Includi i figli delle Infra",
            "notincludechildren": "Non includere i figli delle Infra",
            "label": "Infra",
            "many": "Infra",
            "parent": "Infra padre",
            "site": "Sito",
            "surface": "Superficie globale",
            "surfaces": "Superfici e localizzazioni",
            "zone": "Zona",
            "usage": { "label": "Uso", "many": "Usi" }
        },
        "journal": { "label": "Giornale", "count": "Numero di eventi", "size": "Dimensione del giornale (MB)" },
        "location": { "latitude": "Latitudine", "longitude": "Longitudine" },
        "notificationpolicy": {
            "event": "Evento",
            "global": "Globale",
            "businessaccount": "Specifico per questa attività",
            "sendmail": "Email",
            "isdefault": "Predefinito",
            "isoverride": "Forzato",
            "many": "Notifiche",
            "label": "Notifica",
            "deleteglobal": "Eliminazione globale",
            "deletewac": "Eliminazione attività",
            "resetglobal": "Ripristino globale",
            "resetwac": "Ripristino attività",
            "globalhelp": "Definisci la notifica globalmente per tutte le attività",
            "businessaccounthelp": "Definisci la notifica specificamente per questa attività. Se non definita, si applica la strategia globale"
        },
        "organization": {
            "businesssector": "Settore di attività",
            "code": "Codice organizzazione",
            "create": "Crea la tua organizzazione",
            "createsuccessfully": "La tua organizzazione è stata creata con successo. Ora ne sei l'amministratore",
            "define": "Definisci la tua organizzazione",
            "definesuccessfully": "Richiesta di accesso come membro dell'organizzazione effettuata con successo ...",
            "label": "Organizzazione",
            "legalcode": "Codici legali",
            "legalname": "Nome legale",
            "many": "Organizzazioni",
            "notcertified": "La tua appartenenza all'organizzazione è in fase di validazione ...",
            "near": "Organizzazione vicina",
            "operator": "Organizzazione operativa",
            "other": "Altra organizzazione",
            "otherin": "Altra referenza",
            "owner": "Organizzazione proprietaria",
            "select": "Seleziona nelle tue organizzazioni vicine",
            "setcode": "Inserisci un codice",
            "siret": "Siret",
            "status": { "tovalidate": "In fase di validazione", "validated": "Validata", "deleted": "Eliminata" },
            "type": { "enterprise": "Impresa", "collectivity": "Collettività", "association": "Associazione" },
            "vatin": "Partita IVA",
            "your": "La tua organizzazione"
        },
        "period": {
            "label": "Periodo",
            "undefined": "Altro periodo",
            "se": "secondo/i",
            "mi": "minuto/i",
            "ho": "ora/e",
            "da": "giorno/i",
            "we": "settimana/e",
            "mo": "mese/i",
            "ye": "anno/i"
        },
        "reflect": {
            "alarm": "Allarme",
            "class": { "label": "Classe", "many": "Classi" },
            "criticity": {
                "override": "Specificazione criticità",
                "undefined": "Criticità del giornale",
                "veryhigh": "Molto critico",
                "high": "Critico",
                "normal": "Mediamente critico",
                "low": "Poco critico",
                "verylow": "Molto poco critico",
                "none": "Nessuna criticità"
            },
            "invalidated": "Il riflesso è stato invalidato",
            "state": { "count": "Numero di stati" },
            "label": "Riflesso",
            "localId": "ULI ID locale (nod)",
            "many": "Riflessi",
            "count": "Numero di riflessi",
            "path": "Percorso della risorsa",
            "size": "Dimensione dei riflessi (MB)",
            "validated": "Il riflesso è validato",
            "validateerror": "Errore nella validazione del riflesso"
        },
        "role": { "label": "Ruolo", "many": "Ruoli" },
        "scope": {
            "label": "Scope",
            "many": "Scope",
            "criticitydiffusion": "Notifica email del giornale",
            "criticitydiffusionempty": "Non notificare",
            "query": "Richiesta"
        },
        "smartlocalunit": {
            "alarms": "Allarmi",
            "autovalidresources": "Validazione manuale/automatica dei Flussi e Riflessi dell'ULI",
            "autovalidresourcesno": "Validazione manuale dei Flussi e Riflessi dell'ULI",
            "autovalidresourcesyes": "Validazione automatica dei Flussi e Riflessi dell'ULI",
            "contract": {
                "label": "Contratto",
                "exchangeperiod": "Periodicità degli scambi",
                "heartBeatPeriod": "Periodicità del rendez-vous",
                "fluxminperiod": "Periodo minimo di campionamento dei Flussi",
                "datamax": "Numero massimo di canali",
                "dailyEventMax": "Numero massimo di eventi giornalieri per riflesso",
                "endDate": "Data di fine contratto"
            },
            "exchangedate": "Ultimo scambio",
            "hard": "Hardware",
            "invalidated": "L'ULI è stata invalidata",
            "label": "ULI",
            "late": "Ritardo",
            "maintenance": "Manutenzione",
            "many": "ULI",
            "lateperiod": "Periodo prima del ritardo",
            "latesince": "In ritardo da",
            "nextcall": "Prossima chiamata",
            "networkmedia": { "label": "Media", "lan": "Lan", "gprs": "GPRS", "rtc": "RTC", "g3": "3G", "g4": "4G" },
            "commissioning": {
                "label": "Messa in servizio",
                "deconnected": "Disconnesso",
                "connectwanted": "Connessione richiesta",
                "connected": "Connesso",
                "connectdenied": "Connessione rifiutata"
            },
            "softhard": "Hardware e software",
            "softname": "Software",
            "softversion": "Versione",
            "softdate": "Data",
            "substitutionwid": "Wid di sostituzione (SAV)",
            "validated": "L'ULI è validata",
            "validateerror": "Errore nella validazione dell'ULI",
            "wid": "Wid (identificativo)"
        },
        "tag": { "label": "Tag", "many": "Tag", "isglobal": "Globale", "isglobaldetail": "Globale (Tag sincronizzato con ULI)" },
        "user": {
            "label": "Utente",
            "profile": "Profilo",
            "many": "Utenti",
            "mail": "Email",
            "phone": "Telefono",
            "firstname": "Nome",
            "lastname": "Cognome",
            "photo": "Foto",
            "photocurrent": "Attuale",
            "photonew": "Carica nuova"
        },
        "wum": { "label": "Unità", "unit": "Unità", "function": "Funzione", "type": "Tipo", "material": "Materiale" },
        "status": {
            "label": "Stato",
            "disabled": "Disattivato",
            "tovalidate": "Da validare",
            "validated": "Validato",
            "locked": "Bloccato",
            "offline": "Offline",
            "customtokill": "Eliminazione richiesta",
            "admintokill": "Eliminazione richiesta",
            "killed": "Eliminato"
        },
        "about": "Informazioni",
        "code": "Codice",
        "communication": "Comunicazione",
        "config": "Configurazione",
        "creationdate": "Data di creazione",
        "date": "Data",
        "define": "Definisci",
        "disable": "Disattiva",
        "description": "Descrizione",
        "edit": "Modifica",
        "enabled": "Attivo",
        "extkey": "La tua referenza",
        "help": "Aiuto",
        "invalidate": "Invalida",
        "label": "Etichetta",
        "logo": "Logo",
        "join": "Unirsi",
        "metrics": "Metriche",
        "other": "Altro",
        "picturecurrent": "Attuale",
        "picturenew": "Carica nuovo",
        "or": "O",
        "remark": "Nota",
        "tagsinfras": "Tag e localizzazione",
        "search": "Ricerca",
        "show": "Mostra",
        "summary": "Generale",
        "type": "Tipo",
        "undefined": "-",
        "unknown": "Sconosciuto",
        "updatedate": "Data di modifica",
        "url": "URL",
        "validate": "Valida",
        "youcan": "Puoi",
        "logoutsuccessfull": "Disconnessione avvenuta con successo!",
        "login": "Accedi",
        "processlogin": "Accesso in corso...",
        "processlogincallback": "Finalizzazione dell'accesso",
        "processlogout": "Disconnessione in corso...",
        "processlogoutcallback": "Finalizzazione della disconnessione"
    }
};

export default italianMessages;