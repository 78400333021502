const spanishMessages = {
    "hgw": {
        "activity": {
            "create": "Configurar una actividad entrante",
            "direction": { "in": "Entrante", "out": "Saliente" },
            "globalhelp": "Configure el tipo de actividad con un nombre simple y luego seleccione la pestaña de configuración",
            "formulahelp": "Defina una fórmula de cálculo con una correspondencia con las claves de las fuentes.",
            "formulasample": "Ejemplo: ([A]+[B])/2 requiere definir 2 fuentes con las claves A y B",
            "label": "Actividad",
            "executioncount": "Número de ejecuciones",
            "executiondate": "Última ejecución",
            "in": "Actividad entrante",
            "out": "Actividades salientes",
            "many": "Actividades",
            "metric": {
                "label": "Métrica",
                "delta": "Delta",
                "min": "Mínimo",
                "max": "Máximo",
                "in": "Entrante",
                "out": "Saliente",
                "average": "Promedio",
                "sum": "Suma",
                "count": "Número"
            },
            "type": { "label": "Tipo de actividad" },
            "formula": {
                "key": "Clave",
                "label": "Fórmula",
                "sources": "Datos de flujo fuente",
                "startdate": "Fecha de inicio",
                "targetfluxitem": "Dato de flujo objetivo"
            },
            "view": "Configuración de actividad entrante",
            "undefined": "Ninguna actividad saliente"
        },
        "address": {
            "label": "Dirección",
            "phone": "Teléfono",
            "fax": "Fax",
            "street": "Dirección",
            "postalcode": "Código postal",
            "city": "Ciudad"
        },
        "app": {
            "change": "Navegar a",
            "appselector": "Selector de aplicación",
            "allapps": "Ver todas mis Aplicaciones"
        },
        "authorization": {
            "isvalid": "Validado",
            "tovalidate": "Validar",
            "businessaccount": { "label": "Autorización", "many": "Autorizaciones" },
            "invalidated": "La autorización está invalidada",
            "organization": { "label": "Miembro", "many": "Miembros" },
            "validated": "La autorización está validada",
            "validateerror": "Error al validar la autorización"
        },
        "badge": {
            "centralkind": { "label": "Central", "standard": "Estándar", "dungle": "Llave electrónica", "encoder": "Codificador" },
            "code": "Código",
            "constraint": "Restricciones",
            "deploy": {
                "label": "Despliegue",
                "deployed": "Desplegado",
                "toadd": "Para añadir",
                "toupdate": "Para modificar",
                "toremove": "Para eliminar"
            },
            "error": {
                "label": "Error de configuración",
                "emptyinfra": "Infra no configurada en la ULI",
                "outsideinfra": "Infra de la ULI fuera de la restricción del Badge"
            },
            "emptyuser": "Ningún usuario",
            "enddate": "Fecha de fin",
            "exclude": "Excluir",
            "excluded": "Excluido",
            "infra": "Restricción en Infra",
            "infraempty": "Sin restricciones",
            "invalidated": "El badge está invalidado",
            "keybox": {
                "label": "Cajas de llaves",
                "many": "Resumen de despliegues en las cajas de llaves de las ULIs del Scope"
            },
            "kind": { "label": "Tipo de badge", "many": "Tipos", "wiegand": "Wiegand", "iso2": "ISO2", "dallas": "Dallas" },
            "label": "Badge",
            "many": "Badges",
            "mediatype": { "label": "Tipo de soporte", "keyboard": "Teclado" },
            "startdate": "Fecha de inicio",
            "summary": "Despliegue",
            "usage": {
                "label": "Uso",
                "many": "Usos",
                "masterduplicate": "Duplicación maestra",
                "masterkill": "Eliminación maestra",
                "resourcecommand": "Comando de recurso",
                "access": "Acceso",
                "zonecommand": "Comando de zona"
            },
            "usecount": "Número de usos",
            "uselimit": "Número máximo de usos",
            "validated": "El badge está validado",
            "validateerror": "Error al validar el badge"
        },
        "businessaccount": {
            "allbusinessaccount": "Ver todos mis asuntos",
            "autovalidresources": "Validación manual/automática de las ULIs",
            "autovalidresourcesno": "Validación manual de las ULIs",
            "autovalidresourcesyes": "Validación automática de las ULIs",
            "empty": "Aún no tienes ningún asunto ...",
            "create": "Crear un nuevo asunto",
            "createsuccessfully": "El asunto ha sido creado con éxito y está en proceso de aprovisionamiento ...",
            "label": "Asunto",
            "many": "Asuntos",
            "mybusinessaccount": "Mis asuntos",
            "wac": "Identificador de asunto WIT",
            "join": "Unirse a un asunto",
            "joinsuccessfully": "Solicitud de acceso al asunto realizada con éxito ... El asunto será visible una vez que su autorización sea validada por su administrador",
            "other": "Otro asunto",
            "near": "Asuntos de su organización",
            "select": "Seleccionar un asunto",
            "selectorg": "Seleccione un asunto en su organización",
            "setwac": "Introduzca un WAC (Identificador de asunto WIT)",
            "status": { "provisionning": "Provisionamiento en curso", "deleting": "Archivado en curso", "deleted": "Archivado" },
            "offer": { "label": "Oferta" }
        },
        "country": { "label": "País" },
        "culture": { "label": "Idioma" },
        "device": {
            "lora": {
                "many": "Lora",
                "deveui": "DevEUI",
                "commissioningMode": "Modo",
                "lorawanClass": "Clase",
                "model": "Modelo",
                "manufacturer": "Fabricante"
            }
        },
        "extendedproperty": {
            "label": "Propiedad extendida",
            "many": "Propiedades extendidas",
            "nullable": "Nulo",
            "create": "Agregar una propiedad extendida",
            "edit": "Editar las propiedades extendidas",
            "list": { "label": "Lista de valores", "key": "Valor", "value": "Texto mostrado" },
            "resourcetype": "Tipo de recurso",
            "shared": "Global",
            "valuetype": {
                "label": "Tipo de valor",
                "bool": "Booleano",
                "int": "Entero",
                "decimal": "Decimal",
                "string": "Texto"
            }
        },
        "flux": {
            "label": "Flujo",
            "origin": { "label": "Origen", "acquiring": "Adquisición", "cloud": "Nube" },
            "many": "Flujos",
            "count": "Número de flujos",
            "size": "Tamaño de los flujos (MB)",
            "parent": "Flujo padre",
            "invalidated": "El flujo está invalidado",
            "item": {
                "code": "Etiqueta corta (alfa numérica)",
                "count": "Número de datos",
                "label": "Dato de Flujo",
                "many": "Datos",
                "type": {
                    "label": "Tipo de valor",
                    "boolean": "Booleano",
                    "decimal": "Decimal",
                    "float": "Flotante",
                    "int": "Entero",
                    "money": "Monto",
                    "text": "Texto",
                    "tinyint": "Entero pequeño",
                    "datetime": "Fecha"
                }
            },
            "point": {
                "label": "Punto",
                "many": "Puntos",
                "lastmany": "Últimos puntos",
                "firstdate": "Fecha del primer punto",
                "lastdate": "Fecha del último punto",
                "lastvalue": "Último valor",
                "count": "Número de puntos"
            },
            "validated": "El flujo está validado",
            "validateerror": "Error al validar el flujo"
        },
        "infra": {
            "building": "Edificio",
            "heatedsurface": "Superficie calefaccionada",
            "isheatedsurfaceoverride": "Definir superficie calefaccionada (si no se calcula con hijos)",
            "isoverride": "Bloquear Infra (si no es idéntica a ULI)",
            "issurfaceoverride": "Definir superficie global (si no se calcula con hijos)",
            "includechildren": "Incluir los hijos de las Infras",
            "notincludechildren": "No incluir los hijos de las Infras",
            "label": "Infra",
            "many": "Infras",
            "parent": "Infra padre",
            "site": "Sitio",
            "surface": "Superficie global",
            "surfaces": "Superficies y localizaciones",
            "zone": "Zona",
            "usage": { "label": "Uso", "many": "Usos" }
        },
        "journal": { "label": "Diario", "count": "Número de eventos", "size": "Tamaño del diario (MB)" },
        "location": { "latitude": "Latitud", "longitude": "Longitud" },
        "notificationpolicy": {
            "event": "Evento",
            "global": "Global",
            "businessaccount": "Específico a este asunto",
            "sendmail": "Correo",
            "isdefault": "Por defecto",
            "isoverride": "Forzado",
            "many": "Notificaciones",
            "label": "Notificación",
            "deleteglobal": "Eliminación global",
            "deletewac": "Eliminación asunto",
            "resetglobal": "Restablecimiento global",
            "resetwac": "Restablecimiento asunto",
            "globalhelp": "Definir la notificación globalmente para todos los asuntos",
            "businessaccounthelp": "Definir la notificación específicamente para este asunto. Si no está definido, se aplica la estrategia global"
        },
        "organization": {
            "businesssector": "Ámbito de actividad",
            "code": "Código de organización",
            "create": "Crear su organización",
            "createsuccessfully": "Su organización ha sido creada con éxito. Ahora es el administrador",
            "define": "Definir su organización",
            "definesuccessfully": "Solicitud de acceso como miembro de la organización realizada con éxito ... ",
            "label": "Organización",
            "legalcode": "Códigos legales",
            "legalname": "Nombre legal",
            "many": "Organizaciones",
            "notcertified": "Su pertenencia a la organización está en proceso de validación ...",
            "near": "Organización cercana",
            "operator": "Organización explotadora",
            "other": "Otra organización",
            "otherin": "Otra referencia",
            "owner": "Organización propietaria",
            "select": "Seleccione en sus organizaciones cercanas",
            "setcode": "Introduzca un código",
            "siret": "Siret",
            "status": { "tovalidate": "En proceso de validación", "validated": "Validada", "deleted": "Eliminada" },
            "type": { "enterprise": "Empresa", "collectivity": "Colectividad", "association": "Asociación" },
            "vatin": "IVA",
            "your": "Su organización"
        },
        "period": {
            "label": "Período",
            "undefined": "Otro período",
            "se": "segundo(s)",
            "mi": "minuto(s)",
            "ho": "hora(s)",
            "da": "día(s)",
            "we": "semana(s)",
            "mo": "mes",
            "ye": "año(s)"
        },
        "reflect": {
            "alarm": "Alarma",
            "class": { "label": "Clase", "many": "Clases" },
            "criticity": {
                "override": "Especificación criticidad",
                "undefined": "Criticidad del diario",
                "veryhigh": "Muy crítico",
                "high": "Crítico",
                "normal": "Medianamente crítico",
                "low": "Poco crítico",
                "verylow": "Muy poco crítico",
                "none": "Sin criticidad"
            },
            "invalidated": "El reflejo está invalidado",
            "state": { "count": "Número de estados" },
            "label": "Reflejo",
            "localId": "ULI local Id (nod)",
            "many": "Reflejos",
            "count": "Número de reflejos",
            "path": "Camino del recurso",
            "size": "Tamaño de los reflejos (MB)",
            "validated": "El reflejo está validado",
            "validateerror": "Error al validar el reflejo"
        },
        "role": { "label": "Rol", "many": "Roles" },
        "scope": {
            "label": "Alcance",
            "many": "Alcances",
            "criticitydiffusion": "Notificación por correo del diario",
            "criticitydiffusionempty": "No notificar",
            "query": "Consulta"
        },
        "smartlocalunit": {
            "alarms": "Alarmas",
            "autovalidresources": "Validación manual/automática de Flujos y Reflejos de la ULI",
            "autovalidresourcesno": "Validación manual de Flujos y Reflejos de la ULI",
            "autovalidresourcesyes": "Validación automática de Flujos y Reflejos de la ULI",
            "contract": {
                "label": "Contrato",
                "exchangeperiod": "Periodicidad de los intercambios",
                "heartBeatPeriod": "Periodicidad de la cita",
                "fluxminperiod": "Período mínimo de muestreo de los Flujos",
                "datamax": "Número máximo de canales",
                "dailyEventMax": "Número máximo de eventos diarios por reflejo",
                "endDate": "Fecha de fin del contrato"
            },
            "exchangedate": "Último intercambio",
            "hard": "Hardware",
            "invalidated": "La ULI está invalidada",
            "label": "ULI",
            "late": "Retraso",
            "maintenance": "Mantenimiento",
            "many": "ULIs",
            "lateperiod": "Período antes de retraso",
            "latesince": "En retraso desde",
            "nextcall": "Próxima llamada",
            "networkmedia": { "label": "Medio", "lan": "Lan", "gprs": "GPRS", "rtc": "RTC", "g3": "3G", "g4": "4G" },
            "commissioning": {
                "label": "Puesta en servicio",
                "deconnected": "Desconectado",
                "connectwanted": "Conexión solicitada",
                "connected": "Conectado",
                "connectdenied": "Conexión rechazada"
            },
            "softhard": "Hardware y software",
            "softname": "Software",
            "softversion": "Versión",
            "softdate": "Fecha",
            "substitutionwid": "Wid de sustitución (SAV)",
            "validated": "La ULI está validada",
            "validateerror": "Error al validar la ULI",
            "wid": "Wid (identificador)"
        },
        "tag": { "label": "Etiqueta", "many": "Etiquetas", "isglobal": "Global", "isglobaldetail": "Global (Etiqueta sincronizada con ULIs)" },
        "user": {
            "label": "Usuario",
            "profile": "Perfil",
            "many": "Usuarios",
            "mail": "Correo",
            "phone": "Teléfono",
            "firstname": "Nombre",
            "lastname": "Apellido",
            "photo": "Foto",
            "photocurrent": "Actual",
            "photonew": "Cargar nueva"
        },
        "wum": { "label": "Unidades", "unit": "Unidad", "function": "Función", "type": "Tipo", "material": "Material" },
        "status": {
            "label": "Estado",
            "disabled": "Desactivado",
            "tovalidate": "Por validar",
            "validated": "Validado",
            "locked": "Bloqueado",
            "offline": "Sin conexión",
            "customtokill": "Eliminación solicitada",
            "admintokill": "Eliminación solicitada",
            "killed": "Eliminado"
        },
        "about": "Acerca de",
        "code": "Código",
        "communication": "Comunicación",
        "config": "Configuración",
        "creationdate": "Fecha de creación",
        "date": "Fecha",
        "define": "Definir",
        "disable": "Desactivar",
        "description": "Descripción",
        "edit": "Editar",
        "enabled": "Activo",
        "extkey": "Su referencia",
        "help": "Ayuda",
        "invalidate": "Invalidar",
        "label": "Etiqueta",
        "logo": "Logo",
        "join": "Unirse",
        "metrics": "Métricas",
        "other": "Otro",
        "picturecurrent": "Actual",
        "picturenew": "Cargar nuevo",
        "or": "O",
        "remark": "Observación",
        "tagsinfras": "Etiquetas y localización",
        "search": "Búsqueda",
        "show": "Mostrar",
        "summary": "General",
        "type": "Tipo",
        "undefined": "-",
        "unknown": "Desconocido",
        "updatedate": "Fecha de modificación",
        "url": "Url",
        "validate": "Validar",
        "youcan": "Usted puede",
        "logoutsuccessfull": "¡Se ha desconectado con éxito!",
        "login": "Iniciar sesión",
        "processlogin": "Iniciando sesión ...",
        "processlogincallback": "Finalizando inicio de sesión",
        "processlogout": "Cerrando sesión ...",
        "processlogoutcallback": "Finalizando cierre de sesión"
    }
};

export default spanishMessages;