const englishMessages = {
    "hgw": {
        "activity": {
            "create": "Configure an incoming activity",
            "direction": { "in": "Incoming", "out": "Outgoing" },
            "globalhelp": "Configure the activity type with a simple name, then select the settings tab",
            "formulahelp": "Define a calculation formula corresponding to the source keys.",
            "formulasample": "Example: ([A]+[B])/2 requires defining 2 sources with keys A and B",
            "label": "Activity",
            "executioncount": "Number of executions",
            "executiondate": "Last execution",
            "in": "Incoming activity",
            "out": "Outgoing activities",
            "many": "Activities",
            "metric": {
                "label": "Metric",
                "delta": "Delta",
                "min": "Minimum",
                "max": "Maximum",
                "in": "Incoming",
                "out": "Outgoing",
                "average": "Average",
                "sum": "Sum",
                "count": "Number"
            },
            "type": { "label": "Activity type" },
            "formula": {
                "key": "Key",
                "label": "Formula",
                "sources": "Source flow data",
                "startdate": "Start date",
                "targetfluxitem": "Target flow data"
            },
            "view": "Incoming activity configuration",
            "undefined": "No outgoing activity"
        },
        "address": {
            "label": "Address",
            "phone": "Phone",
            "fax": "Fax",
            "street": "Address",
            "postalcode": "Postal code",
            "city": "City"
        },
        "app": {
            "change": "Navigate to",
            "appselector": "Application selector",
            "allapps": "View all my Applications"
        },
        "authorization": {
            "isvalid": "Validated",
            "tovalidate": "Validate",
            "businessaccount": { "label": "Authorization", "many": "Authorizations" },
            "invalidated": "The authorization is invalidated",
            "organization": { "label": "Member", "many": "Members" },
            "validated": "The authorization is validated",
            "validateerror": "Authorization validation error"
        },
        "badge": {
            "centralkind": {
                "label": "Central",
                "standard": "Standard",
                "dungle": "Dongle",
                "encoder": "Encoder"
            },
            "code": "Code",
            "constraint": "Constraints",
            "deploy": {
                "label": "Deployment",
                "deployed": "Deployed",
                "toadd": "To add",
                "toupdate": "To modify",
                "toremove": "To remove"
            },
            "error": {
                "label": "Configuration error",
                "emptyinfra": "Infra not configured on the ULI",
                "outsideinfra": "ULI's Infra outside the Badge constraint"
            },
            "emptyuser": "No user",
            "enddate": "End date",
            "exclude": "Exclude",
            "excluded": "Excluded",
            "infra": "Infra restriction",
            "infraempty": "No restriction",
            "invalidated": "The badge is invalidated",
            "keybox": {
                "label": "Key boxes",
                "many": "Summary of deployments in key boxes of ULIs in the Scope"
            },
            "kind": {
                "label": "Badge type",
                "many": "Types",
                "wiegand": "Wiegand",
                "iso2": "ISO2",
                "dallas": "Dallas"
            },
            "label": "Badge",
            "many": "Badges",
            "mediatype": { "label": "Media type", "keyboard": "Keyboard" },
            "startdate": "Start date",
            "summary": "Deployment",
            "usage": {
                "label": "Usage",
                "many": "Usages",
                "masterduplicate": "Master duplication",
                "masterkill": "Master deletion",
                "resourcecommand": "Resource command",
                "access": "Access",
                "zonecommand": "Zone command"
            },
            "usecount": "Number of uses",
            "uselimit": "Max number of uses",
            "validated": "The badge is validated",
            "validateerror": "Badge validation error"
        },
        "businessaccount": {
            "allbusinessaccount": "View all my business accounts",
            "autovalidresources": "Manual/automatic validation of ULIs",
            "autovalidresourcesno": "Manual validation of ULIs",
            "autovalidresourcesyes": "Automatic validation of ULIs",
            "empty": "You don't have any business accounts yet...",
            "create": "Create a new business account",
            "createsuccessfully": "The business account has been successfully created and is being provisioned...",
            "label": "Business Account",
            "many": "Business Accounts",
            "mybusinessaccount": "My Business Accounts",
            "wac": "WIT business account identifier",
            "join": "Join a business account",
            "joinsuccessfully": "Access request to the business account successfully made... The business account will be visible once your authorization is validated by its administrator",
            "other": "Other business account",
            "near": "Business accounts of your organization",
            "select": "Select a business account",
            "selectorg": "Select a business account in your organization",
            "setwac": "Enter a WAC (WIT business account identifier)",
            "status": {
                "provisionning": "Provisioning in progress",
                "deleting": "Archiving in progress",
                "deleted": "Archived"
            },
            "offer": { "label": "Offer" }
        },
        "country": { "label": "Country" },
        "culture": { "label": "Language" },
        "device": {
            "lora": {
                "many": "Lora",
                "deveui": "DevEUI",
                "commissioningMode": "Mode",
                "lorawanClass": "Class",
                "model": "Model",
                "manufacturer": "Manufacturer"
            }
        },
        "extendedproperty": {
            "label": "Extended Property",
            "many": "Extended Properties",
            "nullable": "Nullable",
            "create": "Add an extended property",
            "edit": "Edit extended properties",
            "list": { "label": "Value List", "key": "Value", "value": "Displayed text" },
            "resourcetype": "Resource type",
            "shared": "Global",
            "valuetype": {
                "label": "Value type",
                "bool": "Boolean",
                "int": "Integer",
                "decimal": "Decimal",
                "string": "Text"
            }
        },
        "flux": {
            "label": "Flow",
            "origin": { "label": "Origin", "acquiring": "Acquisition", "cloud": "Cloud" },
            "many": "Flows",
            "count": "Number of flows",
            "size": "Flow size (MB)",
            "parent": "Parent flow",
            "invalidated": "The flow is invalidated",
            "item": {
                "code": "Short label (alphanumeric)",
                "count": "Number of data",
                "label": "Flow Data",
                "many": "Data",
                "type": {
                    "label": "Value type",
                    "boolean": "Boolean",
                    "decimal": "Decimal",
                    "float": "Float",
                    "int": "Integer",
                    "money": "Amount",
                    "text": "Text",
                    "tinyint": "Tiny integer",
                    "datetime": "Date"
                }
            },
            "point": {
                "label": "Point",
                "many": "Points",
                "lastmany": "Last points",
                "firstdate": "First point date",
                "lastdate": "Last point date",
                "lastvalue": "Last value",
                "count": "Number of points"
            },
            "validated": "The flow is validated",
            "validateerror": "Flow validation error"
        },
        "infra": {
            "building": "Building",
            "heatedsurface": "Heated surface",
            "isheatedsurfaceoverride": "Set heated surface (otherwise calculated with children)",
            "isoverride": "Lock Infra (otherwise identical to ULI)",
            "issurfaceoverride": "Set total surface (otherwise calculated with children)",
            "includechildren": "Include Infra's children",
            "notincludechildren": "Do not include Infra's children",
            "label": "Infra",
            "many": "Infras",
            "parent": "Parent Infra",
            "site": "Site",
            "surface": "Total surface",
            "surfaces": "Surfaces and locations",
            "zone": "Zone",
            "usage": { "label": "Usage", "many": "Usages" }
        },
        "journal": { "label": "Journal", "count": "Number of events", "size": "Journal size (MB)" },
        "location": { "latitude": "Latitude", "longitude": "Longitude" },
        "notificationpolicy": {
            "event": "Event",
            "global": "Global",
            "businessaccount": "Specific to this business account",
            "sendmail": "Email",
            "isdefault": "Default",
            "isoverride": "Forced",
            "many": "Notifications",
            "label": "Notification",
            "deleteglobal": "Delete global",
            "deletewac": "Delete business account",
            "resetglobal": "Reset global",
            "resetwac": "Reset business account",
            "globalhelp": "Define the notification globally for all business accounts",
            "businessaccounthelp": "Define the notification specifically for this business account. If not defined, the global strategy applies"
        },
        "organization": {
            "businesssector": "Business sector",
            "code": "Organization code",
            "create": "Create your organization",
            "createsuccessfully": "Your organization has been successfully created. You are now its administrator",
            "define": "Define your organization",
            "definesuccessfully": "Request to join the organization as a member successfully made...",
            "label": "Organization",
            "legalcode": "Legal codes",
            "legalname": "Legal name",
            "many": "Organizations",
            "notcertified": "Your membership in the organization is pending validation...",
            "near": "Nearby organizations",
            "operator": "Operating organization",
            "other": "Other organization",
            "otherin": "Other reference",
            "owner": "Owning organization",
            "select": "Select from your nearby organizations",
            "setcode": "Enter a code",
            "siret": "Siret",
            "status": {
                "tovalidate": "Pending validation",
                "validated": "Validated",
                "deleted": "Deleted"
            },
            "type": {
                "enterprise": "Enterprise",
                "collectivity": "Community",
                "association": "Association"
            },
            "vatin": "VATIN",
            "your": "Your organization"
        },
        "period": {
            "label": "Period",
            "undefined": "Other period",
            "se": "second(s)",
            "mi": "minute(s)",
            "ho": "hour(s)",
            "da": "day(s)",
            "we": "week(s)",
            "mo": "month(s)",
            "ye": "year(s)"
        },
        "reflect": {
            "alarm": "Alarm",
            "class": { "label": "Class", "many": "Classes" },
            "criticity": {
                "override": "Criticity specification",
                "undefined": "Journal criticity",
                "veryhigh": "Very critical",
                "high": "Critical",
                "normal": "Moderately critical",
                "low": "Low criticality",
                "verylow": "Very low criticality",
                "none": "No criticality"
            },
            "invalidated": "The reflection is invalidated",
            "state": { "count": "Number of states" },
            "label": "Reflection",
            "localId": "ULI local Id (nod)",
            "many": "Reflections",
            "count": "Number of reflections",
            "path": "Resource path",
            "size": "Reflection size (MB)",
            "validated": "The reflection is validated",
            "validateerror": "Reflection validation error"
        },
        "role": { "label": "Role", "many": "Roles" },
        "scope": {
            "label": "Scope",
            "many": "Scopes",
            "criticitydiffusion": "Journal email notification",
            "criticitydiffusionempty": "Do not notify",
            "query": "Query"
        },
        "smartlocalunit": {
            "alarms": "Alarms",
            "autovalidresources": "Manual/automatic validation of Flows and Reflections of the ULI",
            "autovalidresourcesno": "Manual validation of Flows and Reflections of the ULI",
            "autovalidresourcesyes": "Automatic validation of Flows and Reflections of the ULI",
            "contract": {
                "label": "Contract",
                "exchangeperiod": "Exchange periodicity",
                "heartBeatPeriod": "Appointment periodicity",
                "fluxminperiod": "Minimum sampling period of Flows",
                "datamax": "Max number of channels",
                "dailyEventMax": "Max daily number of events per reflection",
                "endDate": "Contract end date"
            },
            "exchangedate": "Last exchange",
            "hard": "Hardware",
            "invalidated": "The ULI is invalidated",
            "label": "ULI",
            "late": "Delay",
            "maintenance": "Maintenance",
            "many": "ULIs",
            "lateperiod": "Period before delay",
            "latesince": "Delayed since",
            "nextcall": "Next call",
            "networkmedia": {
                "label": "Media",
                "lan": "LAN",
                "gprs": "GPRS",
                "rtc": "RTC",
                "g3": "3G",
                "g4": "4G"
            },
            "commissioning": {
                "label": "Commissioning",
                "deconnected": "Disconnected",
                "connectwanted": "Connection requested",
                "connected": "Connected",
                "connectdenied": "Connection denied"
            },
            "softhard": "Hardware and software",
            "softname": "Software",
            "softversion": "Version",
            "softdate": "Date",
            "substitutionwid": "Substitution Wid (SAV)",
            "validated": "The ULI is validated",
            "validateerror": "ULI validation error",
            "wid": "Wid (identifier)"
        },
        "tag": {
            "label": "Tag",
            "many": "Tags",
            "isglobal": "Global",
            "isglobaldetail": "Global (Tag synced with ULIs)"
        },
        "user": {
            "label": "User",
            "profile": "Profile",
            "many": "Users",
            "mail": "Email",
            "phone": "Phone",
            "firstname": "First name",
            "lastname": "Last name",
            "photo": "Photo",
            "photocurrent": "Current",
            "photonew": "Upload new"
        },
        "wum": {
            "label": "Units",
            "unit": "Unit",
            "function": "Function",
            "type": "Type",
            "material": "Material"
        },
        "status": {
            "label": "Status",
            "disabled": "Disabled",
            "tovalidate": "To validate",
            "validated": "Validated",
            "locked": "Locked",
            "offline": "Offline",
            "customtokill": "Deletion requested",
            "admintokill": "Deletion requested",
            "killed": "Deleted"
        },
        "about": "About",
        "code": "Code",
        "communication": "Communication",
        "config": "Settings",
        "creationdate": "Creation date",
        "date": "Date",
        "define": "Define",
        "disable": "Disable",
        "description": "Description",
        "edit": "Edit",
        "enabled": "Active",
        "extkey": "Your reference",
        "help": "Help",
        "invalidate": "Invalidate",
        "label": "Label",
        "logo": "Logo",
        "join": "Join",
        "metrics": "Metrics",
        "other": "Other",
        "picturecurrent": "Current",
        "picturenew": "Upload new",
        "or": "OR",
        "remark": "Remark",
        "tagsinfras": "Tags and location",
        "search": "Search",
        "show": "Show",
        "summary": "General",
        "type": "Type",
        "undefined": "-",
        "unknown": "Unknown",
        "updatedate": "Modification date",
        "url": "URL",
        "validate": "Validate",
        "youcan": "You can",
        "logoutsuccessfull": "You have been successfully logged out!",
        "login": "Log in",
        "processlogin": "Logging in...",
        "processlogincallback": "Finalizing login",
        "processlogout": "Logging out...",
        "processlogoutcallback": "Finalizing logout"
    }
};

export default englishMessages;
